
import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// import Icon from '../images/icon.png';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
// import Profile from './UserProfile';



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  propertyForm:{
    margin: "auto",
  }

};


// const useStyles = makeStyles(styles);
class Dispatcher extends Component {
  
    constructor(props){
        super(props)
     }
  
    render(){
    const {classes, ...rest }= this.props;
    return (
        <div>
        <Header
        absolute
        color="black"
        brand="Price 'n' Pay"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
            <GridContainer justify={"center"} style={{marginTop: "100px"}}>
                <GridItem xs={12} sm={12} md={10} justify={"center"}>
                 <Card>
              
              <GridContainer justify={"center"}>
              <GridItem xs={12} sm={12} md={8}>
         
            <CardHeader color="primary">
            
              <h4 className={classes.cardTitleWhite}>Dispatcher Info</h4>
              <p className={classes.cardCategoryWhite}> Contract</p>
            </CardHeader>
            </GridItem>
            </GridContainer>
            <GridContainer justify={"center"}>
            <GridItem xs={12} sm={12} md={5}> 
           <h2 className= {classes.cardTitleDanger}> COMPANY: FARMVILLE </h2>
            </GridItem>
             </GridContainer>
             <GridContainer style={{margin: "50px"}}>
            <GridItem xs={12} sm={12} md={4}> 
              <h4> 40 dispatched Ketu</h4>
              </GridItem>
               <GridItem xs={12} sm={12} md={4}> 
              <h4> 90 bags of Mango 40 Ikorodu</h4>
                 </GridItem>
                  <GridItem xs={12} sm={12} md={4}> 
              <h4> 50 dispatched, Ikeja</h4>
                 </GridItem>
            </GridContainer>
            <GridContainer>
            
            <GridItem xs={12} sm={12} md={8}>
            <CardFooter>
              <Button color="primary" onClick={(e)=> this.handleSubmit(e)}>Edit</Button>
            </CardFooter>
            </GridItem>
            </GridContainer>
          
         
          </Card>
        </GridItem>
                </GridContainer>
        </div>
    )
                  }
}








export default withStyles(styles)(Dispatcher)
