
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore'

import { Provider } from 'react-redux';
import configureStore from 'store';
import firebase from 'firebase/app';
import {  LogOut } from './Reducers/actions/loginAction'
import { LOGIN_USER , SIGN_OUT_SUCCESS} from './Reducers/reducers/type';


// core components
import Admin from "layouts/Admin.js";

import Login from "views/pages/login"
import App from "./app";

import AuthRoute from './views/config/auth';

import "assets/css/material-dashboard-react.css?v=1.9.0";



const hist = createBrowserHistory();

const rrfConfig = {
  useFirestoreForProfile: true, userProfile: 'users',

  attachAuthIsReady: true
}


const store = configureStore()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  enableLogging: true,
  logErrors: true,
  createFirestoreInstance
}


const token = localStorage.getItem('RFToken');
console.log("Found token:", token);

try {
  if (token) {
    store.dispatch({ type: LOGIN_USER });
  } else {
    store.dispatch({ type: SIGN_OUT_SUCCESS })
  }

} catch (error) {
  console.log(error)
}


ReactDOM.render(
  <Provider store={store}>
    < ReactReduxFirebaseProvider {...rrfProps}>
      <Router history={hist}>
        <Switch>
          <AuthRoute path="/admin" component={Admin} />
          <AuthRoute path="/app" component={App} />
          <Route path="/login" component={Login} />
          <Redirect from="/" to="/admin" />
        </Switch>
      </Router>
    </ ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// ReactDOM.render(
//   <Provider store={store}>
//   <ReactReduxFirebaseProvider {...rrfProps}>
//   <Router history={hist}>
//     <Switch>
//       <Route path="/admin" component={Admin} />
//       <Route path="/app" render={props => <App {...props} />} />
//       <Route path="/signup" component = {Signup} />
//       <Route path="/login" component  = {Login} />
//       {/* <Route path="/rtl" component={RTL} /> */}
//       <Redirect from="/" to="/login" />
//     </Switch>
//   </Router>
//   </ReactReduxFirebaseProvider>
//   </Provider>,
//   document.getElementById("root")
// );
