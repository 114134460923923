import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


const authRoute = ({component: Component, authenticated, ...rest})=>(
    <Route 
    {...rest}
    render={(props)=> !authenticated? <Redirect to='/login'/> : <Component {...props}/> }
    />

);


authRoute.propTypes = {
    user: PropTypes.object.isRequired

}
  

const mapStateToProps = (state) =>({
    authenticated: state.authState.authenticated
})
export default connect(mapStateToProps)(authRoute);