
import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// import Icon from '../images/icon.png';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
// import Profile from './UserProfile';



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  propertyForm:{
    margin: "auto",
  }

};


// const useStyles = makeStyles(styles);
class Vendor extends Component {
  
    constructor(props){
        super(props)
     }
  
    render(){
    const {classes, ...rest }= this.props;
    return (
        <div>
        <Header
        absolute
        color="black"
        brand="Price 'n' Pay"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
            <GridContainer justify={"center"} style={{marginTop: "100px"}}>
                <GridItem xs={12} sm={12} md={10} justify={"center"}>
          <Card>
     
              <GridContainer justify={"center"}>
              <GridItem xs={12} sm={12} md={8}>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Vendor Info</h4>
              <p className={classes.cardCategoryWhite}> Contract</p>
            </CardHeader>
            </GridItem>
            </GridContainer>
            <CardBody justify={"center"}>
            <GridContainer justify={"center"}>
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.cardTitleDanger}> Name: Paul Lawrence</h2>
                <h4> email: threasd@gmail.com</h4>
              </GridItem>
              </GridContainer>
            
               <GridContainer style={{marginLeft: "100px"}}>
                 <GridItem xs={12} sm={12} md={5}>
              <h4> address: 3, johnson wallace st., Lougborough</h4>
              </GridItem>
               <GridItem xs={12} sm={12} md={2}>
              <h4>  Stocks: 80 </h4>
                 </GridItem>
                 <GridItem xs={12} sm={12} md={3}>
              <h4> Price : 90,0000p </h4>
                   </GridItem>
                 </GridContainer>
                 <GridContainer justify={"center"}>
                 
             <GridItem xs={12} sm={12} md={4}>
              <h4> Cash out Today</h4>
                  </GridItem>
                  </GridContainer>
              
            </CardBody>
            <CardFooter>
              <Button color="success" onClick={(e)=> this.handleSubmit(e)}>Edit</Button>
            </CardFooter>
           
          </Card>
        </GridItem>
                </GridContainer>
        </div>
    )
                  }
}








export default withStyles(styles)(Vendor)
