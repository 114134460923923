import { LOGIN_USER_ERROR, LOGIN_USER, SIGN_OUT_SUCCESS } from "./type"

const initialState = {
  errors: null,
  authenticated: false
}

const authReducer = (state = initialState, action) => {

  switch (action.type) {

    case LOGIN_USER:
      console.log('LOGIN', action.payload)
      return {
        ...state,
        userId: action.payload,
        authenticated: true,
        loading: false,
        message: "Login success"
      }
    case LOGIN_USER_ERROR:
      console.log("failed", action.payload)
      return {
        ...state,
        message: action.payload.message, authenticated: false, loading: false
      }

    case SIGN_OUT_SUCCESS:
      return { ...state, loading: false, message: null, authenticated: false }

    case 'PASSWORD_UPDATE':
      return { ...state, loggedIn: action.payload, authenticated: true }

    case 'PASSWORD_UPDATE':
      return { ...state }

    default:
      return state
  }

}


export default authReducer




