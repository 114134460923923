/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import Markets from "views/Products/markets";
import Profile from "views/pages/profile";



// import RTLPage from "views/RTLPage/RTLPage.js";


const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Agent Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Profile,
    layout: "/admin"
  },

  // {
  //   path: "/markets",
  //   name: "Markets",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: Markets,
  //   layout: "/admin"
  // },

  // {
  //   path: "/agents",
  //   name: "Agents",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: Agents,
  //   layout: "/admin"
  // },

  // {
  //   path: "/disatchers",
  //   name: "Dispatchers",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: Dispatchers,
  //   layout: "/admin"
  // },

  // {
  //   path: "/vendors",
  //   name: "Vendors",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: Vendors,
  //   layout: "/admin"
  // },

  // {
  //   path: "/payment",
  //   name: "Payment",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: Payments,
  //   layout: "/admin"
  // },

  // {
  //   path: "/orders",
  //   name: "Orders",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Orders,
  //   layout: "/admin"
  // },

];

export default dashboardRoutes;
