import React, { Component, useEffect, useState, useCallback } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
// import Icon from '../images/icon.png';
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Modal from "../moda";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { blue } from '@material-ui/core/colors';
//import CreateDispatcher from "./dispatcher";

// core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import {
  clearProductUpdatesListener,
  getDispatcher,
} from "Reducers/actions/dataAction";
import { listenForProductUpdates, clearDispatcherListener, updateOrderDetails, updateOrderStatus } from "Reducers/actions/dataAction";
import { white } from "material-ui/styles/colors";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  propertyForm: {
    margin: "auto",
  },
};

const useStyles = makeStyles(styles => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
}));

export default function({ orderId, vendorId, marketId, deliveryOption, props }) {
  const classes = useStyles();
 

  const dispatch = useDispatch();

  const products = useSelector((state) => state.dataState[vendorId]);
  const dispatchers = useSelector((state) => state.dataState.dispatchers);
  const requireUpdate = useSelector((state) => state.dataState.update);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    console.log("Selected rider:", value);
    if (value) updateOrderDetails(value, orderId, marketId, vendorId, products);
  };

  const updateStatus = (status) => {
    updateOrderStatus(status, orderId, marketId, vendorId, products);
  }

  useEffect(() => {
    dispatch(listenForProductUpdates(orderId, vendorId, marketId));
    if (deliveryOption === 1) {
        dispatch(getDispatcher());
    }
    return () => clearProductUpdatesListener();
  }, []);

  useEffect(() => {
    console.log("Products updated.");
  }, [requireUpdate]);

 

  const statusCheck = () => {
    let mainStatus;
    if (products) {
      let keys = Object.keys(products);
      mainStatus = products[keys[0]].status;
      for (let key of keys) {
        let status = products[key].status;
        if (status === mainStatus) {
          continue;
        } else {
          return false;
        }
      }
    }
    return mainStatus;
  };

  const statusText = (status) => {
    switch(status) {
        case "order_placed":
            return "New order";
        case "order_accepted":
            return "Vendor processing...";
        case "order_processed":
            return "Order ready!";
        case "agent_received_order":
            if (deliveryOption == 1) {
                return "Ready for dispatch";
            } else {
                return "Ready for customer pickup";
            }
        case "dispatcher_received_order":
            return "Dispatched!";
        case "order_enroute":
            return "Order enroute.";
        case "order_delivered":
            return "Completed!";
        default:
            return "";
    }
  }

  const statusAction = (status) => {
    switch(status) {
        case "order_placed":
            return null;
        case "order_accepted":
            return null;
        case "order_processed":
            return (<Button style={{color: white, backgroundColor: "#FF5E00"}} type=" button" onClick={() => updateStatus("agent_received_order")}>Collect order</Button>);
        case "agent_received_order":
            if (deliveryOption == 1) {
                return (<Button color="info" type=" button" onClick={showModal}>Dispatch order</Button>);
            } else {
                return (<Button color="info" type=" button" onClick={() => updateStatus("order_delivered")}>Complete order</Button>);
            }
        case "dispatcher_received_order":
            return (<Button color="info" type=" button" onClick={() => updateStatus("order_enroute")}>Start Delivery</Button>);
        case "order_enroute":
            return (<Button color="info" type=" button" onClick={() => updateStatus("order_delivered")}>Complete order</Button>);
        case "order_delivered":
            return null;
        default:
            return null;
    }
  }

  if (products) {
    return (
      <div>
        <Card>
          <CardHeader color={"info"}>
            <p>
              {products[Object.keys(products)[0]].vendor_name}
            </p>
            <p>{vendorId}</p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {Object.keys(products).map((key) => (
                <GridItem xs={12} sm={12} md={6}>
                  <Card>
                      <CardBody>
                        <p>{moment(products[key].order_timestamp).format("HH:mm:ss A")}</p>
                        <p style={{color: "#FF5E00"}}>{products[key].product_name}</p>
                        <p>
                            {products[key].quantity} {products[key].unit_measurement}
                        </p>
                        <p><b>Status</b></p>
                        <p style={{color: "#FF5E00"}}>{statusText(products[key].status)}</p>
                      </CardBody>
                  </Card>
                </GridItem>
              ))}
            </GridContainer>
            {statusAction(statusCheck())}
          </CardBody>
        </Card>
        <SimpleDialog open={open} onClose={handleClose} />
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
}

function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;
    const dispatch = useDispatch();
    const dispatchers = useSelector(state => state.dataState.dispatchers);

    useEffect(() => {
        dispatch(getDispatcher());
        return () => clearDispatcherListener();
      }, [])

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Select Dispatcher</DialogTitle>
            {dispatchers != null ? <List>
                {dispatchers.map((item) => (
                    <ListItem button onClick={() => handleListItemClick(item)} key={item}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <img src={item.image_url} alt="..." style={{ height: "100px", width: "80px", padding: "20px" }} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.user_name}/>
                    </ListItem>
                ))}
            </List> : <div>Loading...</div>}
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    //selectedValue: PropTypes.string.isRequired,
};