import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

 const createData = (id, Onisha, Delta, Lagos, Ibadan) =>{
    return [id, Onisha, Delta, Lagos, Ibadan];
  }

 

  const rows = [
  createData('078', "Casava", "Mango", "Cashew", "Orange"),
  createData('064', "Millo", "Rebena", "Apple", "Grape"),
  createData('045', "Rice", "Beans", "Paster", "Indomie"),
  createData('06', "Fufu", "Semo", "Pounded Yam", "Flour"),
  createData('04', "Coke", "Fanta", "Orange", "Sprite"),
];

export default function MarketTable(props) {
  const classes = useStyles();

  const handleClick = (id) =>{
    console.log("you click me")
    return props.history.push(`/app/markets/${id}`) 
}
  return (
    <div className={classes.tableResponsive}>
        <Card>
            <CardHeader color="danger"> <h4>Markets</h4>
            </CardHeader>
        
      <Table className={classes.table}>
       
          {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
        
    
  
           <TableHead className={classes.successTableHeader} > 
           
            <TableRow className={classes.tableHeadRow}>  
            <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            ID
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Onisha
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                             Delta
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Lagos
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Ibadan
                  
                  </TableCell>  
             
            </TableRow>
           
          </TableHead>
        
         
       
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow key={row[0]} className={classes.tableBodyRow} onClick={(e)=> handleClick(row[0])}>
                {row.map((res) => {
                  return (
                    <TableCell className={classes.tableCell} key={res}>
                      {res}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      </Card>
    </div>
  );
}

MarketTable.defaultProps = {
  tableHeaderColor: "gray"
};

MarketTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
