

import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import TablePagination from '@material-ui/core/TablePagination';
import moment from "moment";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";


const useStyles = makeStyles(styles);


export default function PaymentsTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const {tableHeaderColor, handleClick, orders } = props;
  if(orders){
  return (
    <div className={classes.tableResponsive}>
        <Card>
            <CardHeader color="info"> <h4>Total Order</h4></CardHeader>
       
      
      <Table className={classes.table}>
       
          {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
      
           <TableHead className={classes.successTableHeader} > 
           
            <TableRow className={classes.tableHeadRow}>  
            <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Order ID
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Address
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Amount
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Status
                  
                  </TableCell>
                  
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Order Time
                  
                  </TableCell>  
             
            </TableRow>
           
          </TableHead>
        
         
       
        <TableBody>
       
        {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => (
          order?
          (
          <TableRow key={order.order_id} className={classes.tableBodyRow} onClick={(e)=> handleClick(order.order_id)}>
               
                 
          <TableCell component="th" scope="row">
               {order.id}
             </TableCell>
             <TableCell align="left">{order.delivery_address}</TableCell>
             <TableCell align="left">{order.total_price}</TableCell>
             <TableCell align="left">{order.status}</TableCell>
             <TableCell align="left"> { moment( order.order_placed_timestamp).calendar()} </TableCell>
             
            </TableRow>
          ): null
          ))}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        </TableBody>
      </Table>
      </Card>
    </div>
  );
          } else{
            return(
              <div>
                Loading...
              </div>
            )
          }
}

PaymentsTable.defaultProps = {
  tableHeaderColor: "gray"
};

PaymentsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};


