import totalFirebaseApp from '../../views/config/config';
import { LOGIN_USER, LOGIN_USER_ERROR, LOADING_DATA, SIGN_OUT_SUCCESS } from "../reducers/type"


let firebase = totalFirebaseApp.agentAuth;
let CustomAuth = totalFirebaseApp.customerAuth;


export const loginAction = (
    email, password, history
) => async dispatch => {
    console.log("Login action called.");
    let crossAuthListener;
    let keyToken = ""
    let userId;

    firebase.signInWithEmailAndPassword(email, password).then((taskResult) => {
        userId = taskResult.user.uid;
        return taskResult.user.getIdToken();
    }).then((token) => {
        keyToken = token
        localStorage.setItem("RFToken", `${keyToken}`)
        let requestKey = totalFirebaseApp.agentFirestore.collection("crossAuth").doc().id;
        let authData = {
            token: keyToken,
            request_key: requestKey
        }
        crossAuthListener = totalFirebaseApp.agentFirestore.collection("crossAuthTokens").doc(requestKey);
        return totalFirebaseApp.agentFirestore.collection("crossAuth").add(authData);
        // return totalFirebaseApp.agentFirestore.collection("crossAuth").add(authData.token);
    }).then(() => {
        crossAuthListener.onSnapshot((snapshot) => {
            if (snapshot != null && snapshot.exists && snapshot && snapshot.data() != null && snapshot.data().status === "OK") {

                let productCustomToken = snapshot.data().custom_token_product;
                let agentCustomToken = snapshot.data().custom_token_agent;
                let customerCustomToken = snapshot.data().custom_token_user;

                let promises = [
                    totalFirebaseApp.productsAuth.signInWithCustomToken(productCustomToken),
                    totalFirebaseApp.customerAuth.signInWithCustomToken(customerCustomToken),

                ]

                return Promise.all(promises).then((results) => {
                    let token = []
                    results.forEach(data => {
                        token.push(data.user.refreshToken)

                    })

                    console.log("Refresh Tokens", token)

                    localStorage.setItem('RFToken', `${token[0]}`)

                    dispatch({
                        type: LOGIN_USER,
                        payload: userId
                    })

                    history.push(`/admin`)
                }).catch((err) => {
                    dispatch({
                        type: LOGIN_USER_ERROR,
                        payload: false
                    });
                });
            }
        })
    }).catch((err) => {
        dispatch({
            type: LOGIN_USER_ERROR,
            payload: err.message
            // authError: err
        });
    });

}


export const LogOut = () => dispatch => {
    localStorage.removeItem('RFToken');
    dispatch({ type: SIGN_OUT_SUCCESS })
}



export const PasswordReset = (email) => {
    return (dispatch) => {
        firebase.sendPasswordResetEmail(email)
            .then(() => {
                dispatch({
                    type: "PASSWORD_RESET"
                })
            })
    }
}

export const PasswordUpdate = (password) => {
    return (dispatch) => {
        firebase.PasswordUpdate(password)
            .then(() => {
                dispatch({
                    type: "PASSWORD_RESET"
                })
            })
    }
}





