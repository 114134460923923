
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk'
import rootReducer from './Reducers/reducers/index'
import totalFirebaseApp from "./views/config/config"
import {reduxFirestore} from 'redux-firestore';



  
const configureStore = (
    state = {authState: {}   
}
) =>{

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(rootReducer, state, composeEnhancers( applyMiddleware(reduxThunk.withExtraArgument()),
 
    reduxFirestore(totalFirebaseApp)
    ));
 
}


export default configureStore;