import {GET_ORDERS, SET_ERRORS, LOADING_DATA, GET_SINGLE_ORDER, GET_CUSTOMER, GET_ORDER_DETAILS, LISTEN_FOR_PRODUCT_UPDATES, GET_DISPATCHERS} from '../reducers/type';

import totalFirebaseApp from "../../views/config/config";
import moment from "moment";

const fauth = totalFirebaseApp.agentAuth;
const db = totalFirebaseApp.agentFirestore;
const dbProd = totalFirebaseApp.productsFirestore;

const dbCustomer = totalFirebaseApp.customerFirestore;

let singletonCursorQuery, singletonOrderQuery, singletonDispatcherQuery;
let singletonProductsQuery = [];

export const listenForOrders = (userId) => dispatch => {
    return new Promise((resolve, reject) => {
        // Get start of the day
        let startOfDay = moment().startOf('month').valueOf(); //todo: change to day
        console.log("Start of day timestamp:", startOfDay);
        let query = db.collection("users").doc(userId).collection("orders").where("order_timestamp", ">=", startOfDay).orderBy("order_timestamp", "desc");

        singletonOrderQuery = query.onSnapshot((snapshot) => {
            let orderList = [];
            snapshot.docs.forEach((doc) => {orderList.push(doc.data());console.log("Agent Orders List:", doc.data());});
            //return resolve(orderList);
            resolve(dispatch({
                type:GET_ORDERS,
                payload: orderList
            }));
        });
    });
}

export const fetchOrdersWithCursor = (orderIds, reducerType) => dispatch => {
    return new Promise((resolve, reject) => {
        //console.log("Order Ids to query:", orderIds, "Reducer:", reducerType);
        // Listen for order details.
        singletonCursorQuery = dbProd.collection("orders").where("order_id", "in", orderIds).onSnapshot((snapshot) => {
            let orders = [];
            snapshot.docs.forEach((doc) => {
                //console.log("Fresh Order data:", doc.data());
                orders.push(doc.data());
            });
            //return resolve(orders);
            resolve(dispatch({
                type: reducerType,
                payload: orders
            }))
        });
    });
}

export const clearCursorListener = () => {
    if (singletonCursorQuery) singletonCursorQuery();
}

export const clearOrderListener = () => {
    if (singletonOrderQuery) singletonOrderQuery();
}

export const clearProductUpdatesListener = () => {
    console.log("Clearing product updates...");
    if (singletonProductsQuery) {
        singletonProductsQuery.map((query) => query());
    }
}

export const clearDispatcherListener = () => {
    if (singletonDispatcherQuery) singletonDispatcherQuery();
}


export const getOrders = (id) => dispatch => {
    // dispatch({type: LOADING_DATA});
    let documents = []
  let orderId;
  let market_id;

//   let myUserId = fauth.currentUser.uid;

// Get start of the day
let startOfDay = moment().startOf('week').valueOf();
console.log("Start of day timestamp:", startOfDay);

 db.collection("users").doc(id).collection("orders").where("order_timestamp", ">=", startOfDay).get()
  .then(querySnapshot => {
      let orderIds = [];
    querySnapshot.forEach(doc => {
        // console.log(doc.id, " => ", doc.data());
        orderId = doc.id;
        orderIds.push(doc.id);
    })
    return orderIds;
})
.then((orderIds)=>{
    //console.log("Orders:", orderIds);
    orderIds.forEach((orderId) => {
        // console.log("All order Ids for the agent", orderId);
        var myOrders = dbProd.collectionGroup('orders')
        .where('order_id', '==', orderId);
        myOrders.get().then(function (querySnapshot) {
            //console.log("Snapshot- Collection Group", querySnapshot.docs.length);
            querySnapshot.forEach(doc => {
                // console.log(doc.id, " => ", doc.data());
                let document = {id: doc.id, ...doc.data()}
                documents.push(document)
            });
        })
    })
    
})
.then(()=>{
      dispatch({
          type:GET_ORDERS,
          payload: documents
      })
  }).catch((err)=>{
      dispatch({
          type: SET_ERRORS,
          payload: []
      })
  })
 
}


//Please name things properly.
// When they click on an order from the table, you should get the orderId and pass to this function.
export const getSingleOrders = (agentId, marketId, orderId) => dispatch => {
    
    return fetchVendorsForOrder(orderId, marketId).then((vendorIds) => {
        //console.log("vendor Ids to Handle:", vendorIds);
        dispatch({
            type: GET_SINGLE_ORDER,
            payload: vendorIds,            
        });
    }).catch((error) => {
        dispatch({type: SET_ERRORS, payload: []});
    })

    // Change to the orderId and marketId passed from above. I am using samples here.
    // return fetchVendorsForOrder(orderId, marketId).then(async (vendorIds) => {
    //     console.log("List of all vendor Ids:", vendorIds);
    //     vendorArr.push(vendorIds)
    //     for(const vendorId of vendorIds) { // This enables us perform asynchronous call inside the loop.
    //         let products = await getAllProductsForAVendor(vendorId, orderId, marketId,agentId );
    //         // console.log("Products for vendor: " + vendorId, products);
    //         // This generates a list of product objects like this:
    //         // [{productId: {all the product data}}, {productId2: {all the product details}},...]
    //         // You can look at the console log to see the raw data.
    //         totalProductsAndVendors.push(products);
           
    //     }

    //     console.log (totalProductsAndVendors)
   
        
    //     // return; // You can now dispatch totalProductsAndVendors
    // }).then(()=>{
    //     dispatch({
    //         type: GET_SINGLE_ORDER,
    //         payload: totalProductsAndVendors,
    //         vendorIds: vendorArr
            
    //     })
      

      
    // }).catch(err =>{
    //     dispatch({
    //         type:SET_ERRORS,
    //         payload: []
    //     })
    // })
}

export const listenForProductUpdates = (orderId, vendorId, marketId) => (dispatch) => {
    let products = {};
    let query = dbProd.collection("orders").doc(orderId).collection("markets").doc(marketId).collection("vendorOrders").doc(vendorId).collection("productsOrdered").onSnapshot((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {products[doc.id] = doc.data();});
        dispatch({type: LISTEN_FOR_PRODUCT_UPDATES + vendorId, payload: products, id: vendorId});
    });
    singletonProductsQuery.push(query);
}



function fetchVendorsForOrder(orderId, marketId) {
    return new Promise((resolve, reject) => {
        return dbProd.collection("orders").doc(orderId).collection("markets").doc(marketId).collection("vendorOrders").get().then((querySnapshot) => {
            //console.log("Snapshots:", querySnapshot.size);
            let vendorIds = [];
            querySnapshot.docs.forEach((doc) => {
                vendorIds.push(doc.id);
            })
            // console.log(vendorIds)
            return resolve(vendorIds); // Now you have a list of all vendor Ids for this order.
        });
    });
}

function getAllProductsForAVendor(vendorId, orderId, marketId, agentId) {
    return new Promise((resolve, reject) => {
        return dbProd.collection("orders").doc(orderId).collection("markets").doc(marketId).collection("vendorOrders").doc(vendorId).collection("productsOrdered").where("agent_id", "==", agentId).get().then((querySnapshot) => {
            // console.log("Product Snapshots:", querySnapshot.size);
            let products = [];
            querySnapshot.docs.forEach((doc) => {
                products.push(doc.data());
            })
            return resolve(products);
        });
    });
}

 export const getCustomer = (id) => dispatch => {

    console.log("Getting customer details with Id:", id);
    dbCustomer.collection("users").doc(id).get().then(doc =>{
        console.log("User:", doc.id, "=>", doc.data());
        dispatch({
            type: GET_CUSTOMER,
            payload: doc.data()
        });
    }).catch(err =>{
        dispatch({
            type: SET_ERRORS,
            payload:{}
        });
    })

}

export const getDispatcher = () => dispatch => {
    let documents = []
    singletonDispatcherQuery = dbProd.collection("dispatchers").get()
        .then(querySnapshot =>{
            querySnapshot.docs.forEach(doc =>{
                if(!doc.exist){
                    console.log("No Doc")
                }
                let document = {id: doc.id, ...doc.data()}
                documents.push(document)
            })
        }).then(()=>{
            dispatch({
                type: GET_DISPATCHERS,
                payload: documents
            })
        });
}

export const updateOrderStatus = async (status, orderId, marketId, vendorId, products) => {
    let keys = Object.keys(products);
    for (let key of keys) {
        products[key].status = status;
        if (status === "agent_received_order") {
            products[key].agent_collection_timestamp = moment().valueOf();
        } else if (status === "order_enroute") {
            products[key].order_enroute_timestamp = moment().valueOf();
        } else if (status === "order_delivered") {
            products[key].delivery_timestamp = moment().valueOf();
        }
        
        await dbProd.collection("orders").doc(orderId).collection("markets").doc(marketId)
            .collection("vendorOrders").doc(vendorId).collection("productsOrdered").doc(key).update(products[key]);
    }
    //console.log("Updated Details:", products);
}

export const updateOrderDetails = async (dispatcher, orderId, marketId, vendorId, products) => {
    let keys = Object.keys(products);
    for (let key of keys) {
        products[key].dispatcher_name = dispatcher.user_name;
        products[key].dispatcher_phone_number = dispatcher.user_phone;
        products[key].status = "dispatcher_received_order";
        products[key].agent_dispatch_timestamp = moment().valueOf();
        await dbProd.collection("orders").doc(orderId).collection("markets").doc(marketId)
            .collection("vendorOrders").doc(vendorId).collection("productsOrdered").doc(key).update(products[key]);
    }
    //console.log("Updated Details:", products);
}

export const toggleMarketStatus = (id, status) => dispatch => {
    db.collection("users").doc(id).update({
        market_status: status === 'Not in market' ? "In market" : "Not in market"
    })
}