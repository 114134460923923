
import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import Market from "views/Products/market"
import Dispatcher from "views/pages/OrderCancelled/singleOrder";
import Vendor from "views/pages/OrderCompleted/singleComplete";
import Order from "views/pages/OrdersInProgress/order";



const appUserRoutes = () => (
    <React.Fragment>

        <Route path="/app/markets/:id" component={Order} />
        <Route path="/app/dispatchers/:id" component={Order} />
        <Route path="/app/vendors/:id" component={Order} />
        <Route path="/app/orders/:order_id/:customer_id/:total_amount/:delivery_option" component={Order} />

    </React.Fragment>
)

export default appUserRoutes;