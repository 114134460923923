import React, { Component, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import withStyles from '@material-ui/core/styles/withStyles';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.js"
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import { connect } from "react-redux";
import { compose } from "redux";

//components

import Orders from "views/pages/OrdersInProgress/orders"
import OrderCompleted from "views/pages/OrderCompleted/completed";
import OrderReAssigned from "views/pages/OrderCancelled/ordersCancel"
import OrderTotal from "views/pages/TotalOrders/total";

// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { getOrders, listenForOrders, clearOrderListener } from "Reducers/actions/dataAction";
import moment from 'moment'

import { bugs, website, server } from "variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Dashboard } from "@material-ui/icons";

const useStyles = makeStyles(styles);


export default function AdminDashboard(props) {

  const classes = useStyles();
  const { ...rest } = props;

  const dispatch = useDispatch();


  const [status, setStatus] = useState({
    progress: true,
    completed: false,
    cancelled: false,
    total: false
  })

  //const [loaded, setLoaded] = useState(false);
  const[listener, setListener] = useState({});
  //const[orders, setOrders] = useState([]);
  const[inProgressOrder, setInProgressOrder] = useState([]);
  const[completedOrders, setCompletedOrders] = useState([]);
  const[cancelledOrders, setCancelledOrders] = useState([]);
  const[totalOrders, setTotalOrders] = useState([]);

  const id = useSelector(state => state.firebase.auth.uid);

  const orders = useSelector(state => state.dataState.orders)
  const requireUpdate = useSelector((state) => state.dataState.update);
  const ordersLength = orders.length;
  //console.log("Orders length outside effect:", ordersLength);

  useEffect(() => {
    console.log("User ID:", id);
    if (id) {
      //dispatch(getOrders(id))
      //setOrders(() => getOrders(id));
      //console.log("Orders from redux:", orders, "R:", r);

      //listenForOrders(id).then((orders) => setOrders(orders));
      dispatch(listenForOrders(id));
      //console.log("New orders:", orders);
    }
    loadData();
    return () => clearOrderListener();
  }, [id, ordersLength]);

  useEffect(() => {
    console.log("Products updated.");
    loadData();
  }, [requireUpdate]);

  const loadData = () => {
    //console.log("Setting data...", orders);
    setStatus({
      progress: true,
      completed: false,
      cancelled: false,
      total: false
    });
    setStatCompleted();
    setStatProgress();
    setStatCancelled();
    setStatTotal();
  }

  const handleRowClick = (orderId, customerId, totalAmount, deliveryOption) => {
    //console.log("you click me")
    return props.history.push(`/app/orders/${orderId}/${customerId}/${totalAmount}/${deliveryOption}`)
  }

  const setStatProgress = () => {
    setInProgressOrder([]);
    orders && orders.map(order => {
      if (order.status === "active") {
        setInProgressOrder((list) => [...list, order.order_id]);
      }
    })
    return inProgressOrder.length;
  }


  const setStatCompleted = () => {
    setCompletedOrders([]);
    orders && orders.map(order => {
      if (order.status === "order_delivered") {
        setCompletedOrders((list) => [...list, order.order_id]);
      }
    })
    return completedOrders.length;
  }

  const setStatCancelled = () => {
    setCancelledOrders([]);
    orders && orders.map(order => {
      if (order.status === "order_cancelled") {
        setCancelledOrders((list) => [...list, order.order_id]);
      }
    })
    return cancelledOrders.length;
  }

  const setStatTotal = () => {
    setTotalOrders([]);
    orders && orders.map((order) => setTotalOrders((list) => [...list, order.order_id]));
    return totalOrders.length;
  }

  if (id) {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>

            <Card onClick={(e) => setStatus({ ...status, progress: true })} >
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Store />
                </CardIcon>

                <p className={classes.cardCategory} style={{ color: "black" }}>Orders In Progress</p>
                <h3 className={classes.cardTitle}> {inProgressOrder.length}</h3>

              </CardHeader>
              <CardFooter stats>

              </CardFooter>
            </Card>

          </GridItem>
          <GridItem xs={12} sm={6} md={4}>

            <Card onClick={(e) => setStatus({ completed: true, progress: false, cancelled: false, total: false })} >
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <DateRange />
                </CardIcon>
                <p className={classes.cardCategory} style={{ color: "black" }}>Orders Completed</p>
                <h3 className={classes.cardTitle}>{completedOrders.length}</h3>
              </CardHeader>
              <CardFooter stats>
              </CardFooter>
            </Card>

          </GridItem>
          {/* <GridItem xs={12} sm={6} md={3}>
            <Card onClick={(e) => setStatus({ cancelled: true, progress: false, completed: false, total: false })}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  < BugReport />
                </CardIcon>
                <p className={classes.cardCategory} style={{ color: "black" }}>Orders<br /> Cancelled</p>
                <h3 className={classes.cardTitle}>{cancelledOrders.length}</h3>
              </CardHeader>
              <CardFooter stats>

              </CardFooter>
            </Card>
          </GridItem> */}
          <GridItem xs={12} sm={6} md={4}>
            <Card onClick={(e) => setStatus({ total: true, completed: false, progress: false, cancelled: false })}>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <Icon>info_line</Icon>
                </CardIcon>
                <p className={classes.cardCategory} style={{ color: "black" }}> Total Orders</p>
                <h3 className={classes.cardTitle}>{totalOrders.length}</h3>
              </CardHeader>
              <CardFooter stats>

              </CardFooter>
            </Card>
          </GridItem>

        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {
              status.progress ? <Orders handleClick={handleRowClick} orders={inProgressOrder} type={"progress"} /> : (status.completed) ? <Orders handleClick={handleRowClick} orders={completedOrders} type={"completed"} /> : (status.cancelled) ? <Orders handleClick={handleRowClick} orders={cancelledOrders} type={"cancelled"} /> : <Orders handleClick={handleRowClick} orders={totalOrders} type={"total"} />
            }
          </GridItem>
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div>
        Loading...
      </div>
    );
  }
}







// class AdminDashboard extends Component {

//   constructor(props){
//     super(props)
//     this.state = {
//       progress: true,
//       completed: false,
//       reassigned: false,
//       total: false,
//       id: this.props.profile.id

//     }

//   }


//   componentDidMount(){

//     this.props.getOrders()
//   }

//     handleProgress = ()=>{
//       this.setState({
//         progress:true,
//         completed:false,
//         reassigned:false,
//         total:false

//       })

//     }

//     handleCompleted = ()=>{
//       this.setState({
//         progress:false,
//         completed:true,
//         reassigned:false,
//         total:false
//       })

//     }

//     handleReassigned = ()=>{
//       this.setState({
//         progress:false,
//         completed:false,
//         reassigned:true,
//         total:false
//       })

//     }

//     handleTotalOrder = ()=>{
//       this.setState({
//         progress:false,
//         completed:false,
//         reassigned:false,
//         total:true
//       })

//     }

//     handleClick = (id) =>{
//       console.log("you click me")
//       return this.props.history.push(`/app/orders/${id}`)


//   }

//   handleClickComplete = (id)=>{
//       console.log("you click me")
//       return this.props.history.push(`/app/orders/${id}`)

//   }

//   handleClickReassigned = (id) =>{ 
//       console.log("you click me")
//       return this.props.history.push(`/app/orders/${id}`)

//   }

//   handleClickTotalOrder = (id)=>{

//       console.log("you click me")
//       return this.props.history.push(`/app/orders/${id}`)

//   }


//   render () {






//   const {classes} = this.props;
//   // console.log(this.props.profile.id)
//   // console.log(this.props.profile)

//   // console.log(this.state)
//   return (
//     <div>
//       <GridContainer>
//         <GridItem xs={12} sm={6} md={3}>

//         <Card onClick = { this.handleProgress} >
//             <CardHeader color="warning" stats icon>
//               <CardIcon color="warning">
//                <Store />
//               </CardIcon>

//               <p className={classes.cardCategory} style={{color: "black"}}>Orders In Progress</p>
//               <h3 className={classes.cardTitle}> 45000    </h3>

//             </CardHeader>
//             <CardFooter stats>

//             </CardFooter>
//           </Card>

//         </GridItem>
//         <GridItem xs={12} sm={6} md={3}>

//         <Card onClick = { this.handleCompleted}>
//             <CardHeader color="success" stats icon>
//               <CardIcon color="success">
//                 <DateRange />
//               </CardIcon>
//               <p className={classes.cardCategory} style={{color: "black"}}>Orders Completed</p>
//               <h3 className={classes.cardTitle}>7000</h3>
//             </CardHeader>
//             <CardFooter stats>
//              </CardFooter>
//           </Card>

//         </GridItem>
//         <GridItem xs={12} sm={6} md={3}>
//         <Card onClick = { this.handleReassigned}>
//             <CardHeader color="primary" stats icon>
//               <CardIcon color="primary">
//                 < BugReport />
//               </CardIcon>
//               <p className={classes.cardCategory} style={{color: "black"}}>Orders<br/> Re-Assigned</p>
//               <h3 className={classes.cardTitle}>75</h3>
//             </CardHeader>
//             <CardFooter stats>

//             </CardFooter>
//           </Card>
//         </GridItem>
//         <GridItem xs={12} sm={6} md={3}>
//         <Card onClick = { this.handleTotalOrder}>
//             <CardHeader color="info" stats icon>
//               <CardIcon color="info">
//                 <Icon>info_line</Icon>
//               </CardIcon>
//               <p className={classes.cardCategory} style={{color: "black"}}> Total <br/> Orders</p>
//               <h3 className={classes.cardTitle}>7000</h3>
//             </CardHeader>
//             <CardFooter stats>

//             </CardFooter>
//           </Card>
//         </GridItem>

//       </GridContainer>

//       <GridContainer>
//         <GridItem xs={12} sm={12} md={12}>
//         {
//           this.state.progress?<Orders handleClick={this.handleClick}/>:(this.state.completed)?<OrderCompleted handleClick={this.handleClickComplete}/>:(this.state.reassigned)?<OrderReAssigned handleClick={this.handleClickReassigned}/>:<OrderTotal handleClick={this.handleClickTotalOrder}/>


//         }

//         </GridItem>
//       </GridContainer>
//     </div>
//   );
//  }
// }


// const mapStateToProps = (state)=>{
//   // console.log(state)
//   return{
//     orders: state.dataState.orders,
//     profile: state.firebase.profile
//   }

// }


// const mapDispatchToProps = (dispatch)=> {
//   return {
//     getOrders : (id) => {
//         dispatch(getOrders(id))
//       }
// }

// }

// export default compose(connect(mapStateToProps,  mapDispatchToProps),
// withStyles(styles))(AdminDashboard)
