
import firebase from 'firebase';
// import 'firebase/auth';
// import 'firebase/firestore';

const totalFirebaseApp ={};

// Initialize Firebase

// [DEFAULT]


 
const  agentConfig = {
    apiKey: "AIzaSyDF1DFLben0rgTPp_klDpba9bUT9r_qoa4",
    authDomain: "pricenpay-agent.firebaseapp.com",
    databaseURL: "https://pricenpay-agent.firebaseio.com",
    projectId: "pricenpay-agent",
    storageBucket: "pricenpay-agent.appspot.com",
    messagingSenderId: "97298257162",
    appId: "1:97298257162:web:0dd268cc9c9949060396f3",
    measurementId: "G-PVJJH0822Y"
};

let agentConfigApp = firebase.initializeApp(agentConfig);
// let agentConfigDatabase = agentConfigApp.database();
let agentConfigFirestore = agentConfigApp.firestore();


// totalFirebaseApp.agentFirebase = agentConfigDatabase;
totalFirebaseApp.agentFirestore =agentConfigFirestore;
totalFirebaseApp.agentAuth = agentConfigApp.auth();





const productConfig =  {
    apiKey: "AIzaSyBNE0lhnxGeZzVuGaaZIX2iyn9SU3ElN4c",
    authDomain: "pricenpay-product.firebaseapp.com",
    databaseURL: "https://pricenpay-product.firebaseio.com",
    projectId: "pricenpay-product",
    storageBucket: "pricenpay-product.appspot.com",
    messagingSenderId: "168746972139",
    appId: "1:168746972139:web:bc858dd5b41fb8f37fdfae",
    measurementId: "G-ESPB9J65GH"
};

let productsApp = firebase.initializeApp(productConfig, "PRODUCT_CONFIG");

let productFirestore = productsApp.firestore();

totalFirebaseApp.productsAuth = productsApp.auth();

totalFirebaseApp.productsFirestore = productFirestore;




const adminConfig = {
    apiKey: "AIzaSyC-ujiz0VMfp86G9IikR7N4KSbverOMWXU",
    authDomain: "pricenpay-admin.firebaseapp.com",
    databaseURL: "https://pricenpay-admin.firebaseio.com",
    projectId: "pricenpay-admin",
    storageBucket: "pricenpay-admin.appspot.com",
    messagingSenderId: "897719503923",
    appId: "1:897719503923:web:2168269ec15d7a613b6746",
    measurementId: "G-XF11XMEGYZ"
};

let priceAdminApp = firebase.initializeApp(adminConfig, "ADMIN")
let adminFirestore = priceAdminApp.firestore();
let adminFirebase = priceAdminApp.database();


totalFirebaseApp.adminFireabase = adminFirebase;
totalFirebaseApp.adminFirestore = adminFirestore;
totalFirebaseApp.adminAuth = priceAdminApp.auth();



const customerConfig = {
    apiKey: "AIzaSyCrnvjY5ez21rt5mecWCxTLkbyHW-L_uHU",
    authDomain: "pricenpay.firebaseapp.com",
    databaseURL: "https://pricenpay.firebaseio.com",
    projectId: "pricenpay",
    storageBucket: "pricenpay.appspot.com",
    messagingSenderId: "528709044545",
    appId: "1:528709044545:web:1f70714b0ab7c325aef9c0",
    measurementId: "G-5Y1P1S8XFQ"
  };
  // Initialize Firebase

  let customerApp = firebase.initializeApp(customerConfig, "CUSTOMER")
  let customerFirestore = customerApp.firestore();
  let customerFirebase = customerApp.database();

  totalFirebaseApp.customerFirestore = customerFirestore;
  totalFirebaseApp.customerFirebase = customerFirebase;
  totalFirebaseApp.customerAuth = customerApp.auth();




 export default totalFirebaseApp;