import React, { Component, useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes, { element } from "prop-types";
// import Icon from '../images/icon.png';
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import ProductScreen from "./productScreen";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// import Profile from './UserProfile';
import { getCustomer } from "Reducers/actions/dataAction";
import { formatMoney } from "Utils/utils";



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  propertyForm: {
    margin: "auto",
  },
};

const useStyles = makeStyles((styles) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    padding: styles.spacing(2),
    color: styles.palette.text.secondary,
  }
}));

export default function ({
  marketId,
  orderId,
  vendorIds,
  market,
  customerId,
  totalAmount,
  deliveryOption,
  props,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [load, setLoad] = useState(false);
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const customer = useSelector((state) => state.dataState.customer);
  const profile = useSelector((state) => state.firebase.profile);
  const singleVendorProducts = useSelector((state) => state.dataState[vendorIds[0]]);
  let cId = null;
  if (singleVendorProducts) {
    cId = singleVendorProducts[Object.keys(singleVendorProducts)[0]].customer_id;
  }

  console.log("customer", customer);

  const { ...rest } = props;

  useEffect(() => {
    if (cId) {
      dispatch(getCustomer(cId));
    }
    setLoad(true);
  }, [cId]);

  if (customer) {
    return (
      <div>
        <Header
          absolute
          color="black"
          brand="Price n' Pay"
          rightLinks={<HeaderLinks />}
          {...rest}
        />

        <GridContainer justify={"center"} style={{ marginTop: "100px" }}>
          <GridItem xs={12} sm={12} md={10}>
            <div>
              <Card>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader color="info">
                      <h4 className={classes.cardTitleWhite}>
                        ORDER DETAILS
                      </h4>
                      <p className={classes.cardCategoryWhite}>
                        {" "}
                        {orderId}
                      </p>
                    </CardHeader>
                  </GridItem>
                </GridContainer>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <h4
                        className={classes.cardCategory}
                        style={{ color: "#FF6347", fontWeight: "500" }}
                      >
                        {" "}
                        CUSTOMER DETAILS{" "}
                      </h4>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <h4
                        className={classes.cardTitle}
                        style={{ color: "#000000", fontWeight: "500" }}
                      >
                        Customer Id
                      </h4>
                      <p> {customer.user_id}</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <h4
                        className={classes.cardTitle}
                        style={{ color: "#000000", fontWeight: "500" }}
                      >
                        {" "}
                        Customer name
                      </h4>
                      <p> {customer.name}</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <h4
                        className={classes.cardTitle}
                        style={{ color: "#000000", fontWeight: "500" }}
                      >
                        Customer phone number
                      </h4>
                      <p> {customer.phone_number}</p>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <h4
                        className={classes.cardTitle}
                        // style={{ color: "#000000", fontWeight: "500" }}
                      >
                        Total amount
                      </h4>
                      <p style={{color: "#FF5E00"}}><b>{"₦"}{formatMoney(totalAmount)}</b></p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <h4
                        className={classes.cardTitle}
                        // style={{ color: "#000000", fontWeight: "500" }}
                      >
                        {" "}
                        Delivery option
                      </h4>
                      <p style={{color: "#FF5E00"}}><b>{deliveryOption == 0 ? "No delivery" : "Delivery Included"}</b></p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <h4
                        className={classes.cardTitle}
                        // style={{ color: "#000000", fontWeight: "500" }}
                      >
                        Number of vendors
                      </h4>
                      <p style={{color: "#FF5E00"}}><b>{vendorIds.length}</b></p>
                    </GridItem>
                  </GridContainer>
                  <hr />

            

                 <div className={classes.root}>
                  <GridContainer>
                      {vendorIds && vendorIds.map(vendorId => (
                        <GridItem xs={12} sm={12} md={6}>
                          <ProductScreen className={classes.card} orderId={orderId} vendorId={vendorId} marketId={marketId} deliveryOption={deliveryOption}/>
                        </GridItem>
                      ))}
                  </GridContainer>
                 </div>
                  
                </CardBody>
            
              </Card>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  } else {
    return <div>Loading....</div>;
  }
}


