export const GET_ORDERS = "GET_ORDERS";
export const SET_ERRORS = "SET_ERRORS";
export const  LOADING_DATA = " LOADING_DATA";
export const LOGIN_USER = "LOGING_USER";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_ORDER_IN_PROGRESS_DETAILS = "GET_ORDER_IN_PROGRESS_DETAILS";
export const GET_COMPLETED_ORDER_DETAILS = "GET_COMPLETED_ORDER_DETAILS";
export const GET_CANCENCELLED_ORDER_DETAILS = "GET_CANCENCELLED_ORDER_DETAILS";
export const GET_TOTAL_ORDER_DETAILS = "GET_TOTAL_ORDER_DETAILS";
export const LISTEN_FOR_PRODUCT_UPDATES = "LISTEN_FOR_PRODUCT_UPDATES";
export const GET_DISPATCHERS = "GET_DISPATCHERS";
