import React, {Component} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import {  firestoreConnect} from "react-redux-firebase";
import { connect } from 'react-redux';
import { compose } from "redux";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import { toggleMarketStatus } from "../../Reducers/actions/dataAction";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleMarketPresence(id, status) {
    this.props.toggleStatus(id, status);
  }

  render() {
    const { classes, profile } = this.props
    const { ...rest } = this.props
    //console.log(profile)
    return (
      <div>
        <Header
          absolute
          color="black"
          brand="Price n' Pay"
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <GridContainer justify={"center"}
          style={{
            marginTop: "100px",
            height: "900px"
          }}>

          <GridItem xs={12} sm={12} md={8}>
            <Card profile>
              <CardAvatar profile>
                <a href="#" onClick={e => e.preventDefault()}>
                  <img src={profile.user_avatar} alt="..." />
                </a>
              </CardAvatar>
              <CardBody>
                <GridContainer >
                  <GridItem xs={12} sm={12} md={4}>
                    <h6 className={classes.cardCategory} style={{ color: "#FF6347", fontWeight: "500" }}>{profile.user_name}</h6>
                    <h4 className={classes.cardTitle}>Agent </h4>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <h4 className={classes.cardTitle} style={{ fontWeight: "400" }}>Account Id</h4>
                    <p> {profile.user_id}</p>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <h4 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "400" }}>EMAIL</h4>
                    <p> {profile.user_email}</p>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <h4 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "400" }}>PHONE NUMBER</h4>
                    <p> {profile.user_phone_number}</p>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <h4 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "400" }}>TOTAL ORDERS</h4>
                    <p> {profile.total_orders}</p>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <h4 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "400" }}>MARKET</h4>
                    <p> {profile.market}</p>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <h4 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "400" }}>RESASSIGNED ORDERS</h4>
                    <p> {profile.reassigned_orders}</p>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <h4 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "400" }}>STATUS</h4>
                    <p style={{ color: "green" }}> {profile.status}</p>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <h4 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "400" }}>MARKET STATUS</h4>
                    <p style={{ color: "#FF6347" }}> {profile.market_status}</p>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <h4 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "400" }}>CREATION DATE</h4>
                    <p> {moment(profile.creation_date).format("DD MMM YYYY hh:mm a")}</p>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={3}>
                    <Button onClick={() => this.toggleMarketPresence(profile.user_id, profile.market_status)} style={{ backgroundColor: profile.market_status==='Not in market'? "#00BFFE":"#FF6347", fontWeight: "500" }}>
                      {profile.market_status === 'Not in market' ? 'Open Market' : 'I Don Close'}
              </Button>
                  </GridItem>
                  {/* <GridItem xs={12} sm={4} md={3}>
                <Button color="primary">
               ACTIVE ORDERS
              </Button>
                </GridItem> */}
                  {/* <GridItem xs={12} sm={4} md={3}>
                <Button color="primary">
                RE-ASSIGNED ORDERS
              </Button>
                </GridItem> */}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state)=>{
      
  return{
    properties: state.firestore.ordered.users,
    profile: state.firebase.profile
  }
  
}

const mapActionToProps = dispatch => {
  return {
    toggleStatus: (id, status) => dispatch(toggleMarketStatus(id, status))
  };
}
    
export default compose(connect(mapStateToProps, mapActionToProps),
firestoreConnect((props)=>[
  {collection: 'users'},
]),
withStyles(styles))(Profile)













