import {
  LOADING_DATA,
  SET_ERRORS,
  GET_ORDERS,
  GET_SINGLE_ORDER,
  GET_CUSTOMER,
  GET_ORDER_IN_PROGRESS_DETAILS,
  GET_COMPLETED_ORDER_DETAILS,
  GET_CANCENCELLED_ORDER_DETAILS,
  GET_TOTAL_ORDER_DETAILS,
  LISTEN_FOR_PRODUCT_UPDATES,
  GET_DISPATCHERS,
} from "./type";

const initialState = {
  orders: [],
  errors: null,
  order: [],
  customer: {},
  vendors: [],
  dispatchers: [],
};

export default function(state = initialState, action) {
  console.log("State to dispatch:", action.type);
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: false,
        authenticated: true,
      };

    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
        update: Math.random(),
      };

    case GET_DISPATCHERS:
      return {
        ...state,
        dispatchers: action.payload,
        loading: false,
        update: Math.random(),
      };
    case GET_ORDER_IN_PROGRESS_DETAILS:
      return {
        ...state,
        ordersInProgress: action.payload,
        loading: false,
      };
    case GET_COMPLETED_ORDER_DETAILS:
      return {
        ...state,
        completedOrders: action.payload,
        loading: false,
      };
    case GET_CANCENCELLED_ORDER_DETAILS:
      return {
        ...state,
        cancelledOrders: action.payload,
        loading: false,
      };
    case GET_TOTAL_ORDER_DETAILS:
      return {
        ...state,
        allOrders: action.payload,
        loading: false,
      };
    case GET_SINGLE_ORDER:
      console.log(action.payload.order);
      return {
        ...state,
        vendorIds: action.payload,
        loading: false,
      };
    case GET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
        loading: false,
      };

    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        authenticated: false,
      };
    case LISTEN_FOR_PRODUCT_UPDATES + action.id:
      return {
        ...state,
        loading: false,
        [action.id]: action.payload,
        products: action.payload,
        update: Math.random(),
      };
    default:
      return state;
  }
}
