
import React, { Component, useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// import Icon from '../images/icon.png';
import { Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import LoadScreen from "./loadScreen";
// import Profile from './UserProfile';
import { getSingleOrders, getCustomer } from "Reducers/actions/dataAction"
import { GET_ORDER_IN_PROGRESS_DETAILS } from "Reducers/reducers/type";
import { GET_COMPLETED_ORDER_DETAILS } from "Reducers/reducers/type";
import { GET_CANCENCELLED_ORDER_DETAILS } from "Reducers/reducers/type";
import { GET_TOTAL_ORDER_DETAILS } from "Reducers/reducers/type";


const useStyles = makeStyles(styles);

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  propertyForm: {
    margin: "auto",
  }

};



export default function Order(props) {

  const dispatch = useDispatch();
  
 
  const { ...rest } = props;

  //const order = useSelector(state => state.dataState.order)
  const profile = useSelector(state => state.firebase.profile)
  const vendorIds = useSelector(state => state.dataState.vendorIds)


  const [load, setLoad] = useState(false)
  console.log("Input data:", props.match.params);
  const orderId = props.match.params.order_id;
  const customerId = props.match.params.customer_id;
  const totalAmount = props.match.params.total_amount;
  const deliveryOption = props.match.params.delivery_option;
  //console.log("print id:", orderId)
  //console.log("Order vendor Ids", vendorIds)
  //console.log(profile)

 

  
 
  const classes = useStyles();
  useEffect(() => {
    dispatch(getSingleOrders(profile.id, profile.market_id, orderId)) 
   
    setLoad(true)
    console.log("iam here...")
  }, [])

  if (vendorIds) {
    return( 
      <div> 
        < LoadScreen marketId={profile.market_id} orderId={orderId} vendorIds={vendorIds} market={profile.market} customerId={customerId} totalAmount={totalAmount} deliveryOption={deliveryOption} {...props}/>
      </div>
       
    )

    }else{
      return(
        <div>
          Loading....
        </div>
      )
    }
  }































